import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {postUserEducationRequestInsert, postUserEducationRequestUpdate} from "@/network";
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {ParamRegistEdu} from "@/network/apis/types";

interface Props {
    params: ParamRegistEdu;
    isEdit: boolean;
}

interface ResponseData {
    result: number;
}

export function useRegistUserEducationRequest() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((props: Props) => {
        const {params, isEdit} = props
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            const request = isEdit ? postUserEducationRequestUpdate : postUserEducationRequestInsert
            showGlobalLoading(
                request(params, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        const {result} = data
                        if(result >= 1){
                            resolve({result})
                            alert('저장되었습니다.')
                        }else if(result === -2){
                            alert('이미 수강중인 교육이 있습니다.')
                            reject()
                        }else if(result === -3){
                            alert('해당 교육은 인원이 초과되어 신청 할 수 없습니다.')
                            reject()
                        }else{
                            alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                            reject()
                        }
                    })
                    .catch(() => {
                        alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                        reject()
                    })
            )
        })

    }, [prevCanceler, showGlobalLoading])
}