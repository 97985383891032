import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 신청가능버튼 클릭시 확인 api
 * @param params
 * @param config
 */
export function getUserEducationEduApplyCheck(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/education/edu/apply/check.do', {
        ...restConfig,
        params: {
            eduNo,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 파라미터 없음
 */
interface Params {
    eduNo: number;
}

/**
 * enableYn: Y 이면 신청 가능.
 */
interface ResponseData {
    result: {
        eduNo: number;
        enableYn: string;
    };
}

