import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {getUserEducationEduApplyCheck,} from "@/network";

interface SearchParams {
    eduNo: number;
}

interface ResponseData {
    result: {eduNo: number; enableYn: string;};
}

export function useGetUserEducationEduApplyCheck() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: SearchParams) => {
        const {eduNo} = params
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                getUserEducationEduApplyCheck({eduNo}, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        resolve(data)
                    })
                    .catch(() => {
                        reject()
                    })
            )
        })
    }, [prevCanceler, showGlobalLoading])
}
