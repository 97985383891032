export enum RoutePath {
  Main = '/usr/main.do',
  MemberJoin = '/usr/member/join.do',
  MemberInfo = '/usr/member/info.do',
  EduList = '/usr/edu/list.do',
  EduCourseList = '/usr/edu/course/list.do',
  EduPassList = '/usr/edu/pass/list.do',
  NoticeList = '/usr/notice/list.do',
  NoticeDetail = '/usr/notice/detail.do',
  DataList = '/usr/data/list.do',
  DataDetail = '/usr/data/detail.do',
  QnaList = '/usr/qna/list.do',
  QnaDetail = '/usr/qna/detail.do',
  QnaWrite = '/usr/qna/write.do',
  Error = '/usr/error.do',
}