import React, {useCallback, useMemo, useState} from 'react'
import {RoutePath} from "@/pages/routes";
import imgLogo from '@/assets/images/logo@3x.png';
import imgLogoSummary from '@/assets/images/logo_summary@3x.png';
import imgAsideClose from '@/assets/images/ico_aside_close.svg';
import imgMoMenu from '@/assets/images/ico_mobile_menu.svg';
import {useLoginModal} from "@/components";
import {useLocation, useNavigate} from "react-router-dom";
import {useUserLevelNm, useUserLoggedIn} from "@/utils";
import {useSelector} from "react-redux";
import {selectMyInfo} from "@/store/my-info";
import {getUserEducationCourseList, getUserEducationPassList} from "@/network";
import {qs} from "url-parse";

function Nav() {
    const [asideOn, setAsideOn] = useState(false)
    const loginModal = useLoginModal()
    const navigate = useNavigate()
    const location = useLocation()
    const pathname = useMemo(() => location.pathname, [location])
    const {loggedIn} = useUserLoggedIn()
    const {userLevelNm} = useUserLevelNm()
    const myInfo = useSelector(selectMyInfo)

    const showLoginModal = useCallback(() => {
        loginModal({
            onOk: () => {},
        })
    }, [loginModal])

    const handleMobileAsideBtnClick = useCallback(() => {
        setAsideOn(!asideOn)
    }, [asideOn])

    const handleNavItemClick = useCallback((link: RoutePath) => {
        if(!loggedIn && link !== RoutePath.Main){
            showLoginModal()
        }else{
            navigate({pathname: link}, {replace: location.pathname === link})
            setAsideOn(false)
        }

    }, [navigate, location, loggedIn, showLoginModal])

    const handleGoEduCourseClick = useCallback(async () => {
        const {data: {list: passList}} = await getUserEducationPassList({})
        const {data: {list: courseList}} = await getUserEducationCourseList({})
        const eduClassList = courseList && courseList.map((item) => {
            const findItem = passList.find((item2) => item2.applyNo === item.applyNo)
            return {
                ...item, ...findItem
            }
        })
        const courseItem = eduClassList.find((item) => item.passYn === 'N' || item.surveyYn === 'N');
        if(courseItem){
            navigate({pathname: RoutePath.EduCourseList, search: qs.stringify({eduNo: courseItem.eduNo})})
        }else{
            alert('진행중인 교육이 없습니다.')
        }
    }, [navigate])

    const handleLogoutClick = useCallback(() => {
        if(window.confirm('로그아웃 하시겠습니까?')){
            window.location.href = '/uat/uia/actionLogout.do'
        }
    }, [])

    const handleMemberInfoClick = useCallback(() => {
        navigate({pathname: RoutePath.MemberInfo})
        setAsideOn(false)
    }, [navigate])

    const handleLoginClick = useCallback(() => {
        showLoginModal()
        setAsideOn(false)
    }, [showLoginModal])

    const handleJoinClick = useCallback(() => {
        navigate({pathname: RoutePath.MemberJoin})
        setAsideOn(false)
    }, [navigate])

    return (
        <div className={`aside ${asideOn ? 'type-on' : ''}`}>
            <div className={'aside-mobile'}>
                <button type={'button'} className={'mobile-menu-btn'} onClick={handleMobileAsideBtnClick}>
                    <img alt="remis" src={imgMoMenu} className={'mobile-menu'} />
                </button>
                <button className={'mobile-logo-btn'} onClick={() => handleNavItemClick(RoutePath.Main)}>
                    <img alt="remis" src={imgLogo} className={'mobile-logo'} />
                    <img alt="remis" src={imgLogoSummary} className={'mobile-logo-summary'} />
                </button>
            </div>
            <div className={'aside-content'}>
                <button type={'button'} className={'aside-close-btn'} onClick={() => setAsideOn(false)}>
                    <img alt="remis" src={imgAsideClose} className={'aside-close-ico'} />
                </button>
                <button className={'logo-btn'} onClick={() => handleNavItemClick(RoutePath.Main)}>
                    <img alt="remis" src={imgLogo} className={'logo'} />
                    <img alt="remis" src={imgLogoSummary} className={'logo-summary'} />
                </button>
                <div className={'member-type'}>
                    <div>{userLevelNm}</div>
                    {myInfo && myInfo.loginVO && (<div className={'user-nm'}>{myInfo.loginVO.userNm}<span>님 로그인중입니다</span></div>)}
                </div>
                <div className={'snb'}>
                    <div className={'snb-item type-extend'}>
                        <button type={'button'} className={`snb-btn ${pathname === '/usr/edu/list.do' ? 'type-on' : ''}`} onClick={() => handleNavItemClick(RoutePath.EduList)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -2 22 22" fill="currentColor" width="35" height="35">
                                <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                            </svg>

                            <span className={'snb-text'}>교육신청</span>
                        </button>
                    </div>
                    <div className={'snb-item type-extend'}>
                        <button type={'button'} className={`snb-btn ${pathname === '/usr/edu/course/list.do' ? 'type-on' : ''}`} onClick={handleGoEduCourseClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 24 24" fill="currentColor" width="33" height="33">
                                <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
                            </svg>
                            <span className={'snb-text'}>나의 강의실</span>
                        </button>
                    </div>
                    <div className={'snb-item type-extend'}>
                        <button type={'button'} className={`snb-btn ${pathname.search('/usr/edu/pass') >= 0 ? 'type-on' : ''}`} onClick={() => handleNavItemClick(RoutePath.EduPassList)}>
                            <svg className={'snb-ico'} xmlns="http://www.w3.org/2000/svg" width="33.118" height="22.499">
                                <g>
                                    <path d="m617.1 267.381-12.554 5.119a.9.9 0 0 0-.013 1.663l12.516 5.331a9.019 9.019 0 0 0 7 .028l12.5-5.208a.9.9 0 0 0 0-1.663l-12.575-5.24a9.018 9.018 0 0 0-6.874-.03z" transform="translate(-603.985 -266.714)"/>
                                    <path d="M634.837 301.12a9.017 9.017 0 0 1-7-.028l-7-2.983v6.791a1.621 1.621 0 0 0 .537 1.209c1.158 1.039 4.259 3.208 9.963 3.208s8.805-2.169 9.964-3.208a1.621 1.621 0 0 0 .537-1.209v-6.692z" transform="translate(-614.771 -286.817)"/>
                                    <path d="m688.224 301.163-.506-1.013v-6.984l-1.5.625v6.359l-.506 1.013a2.307 2.307 0 0 0 .432 2.662l.824.824.824-.824a2.3 2.3 0 0 0 .432-2.662z" transform="translate(-656.159 -283.652)"/>
                                </g>
                            </svg>
                            <span className={'snb-text'}>교육 수료증</span>
                        </button>
                    </div>
                    <div className={'snb-item type-extend'}>
                        <button type={'button'} className={`snb-btn ${pathname.search('/usr/notice') >= 0 ? 'type-on' : ''}`} onClick={() => handleNavItemClick(RoutePath.NoticeList)}>
                            <svg className={'snb-ico'} xmlns="http://www.w3.org/2000/svg" width="30" height="27.864">
                                <g>
                                    <path d="M645.851 423.227 658 429.976a.911.911 0 0 0 1.353-.8v-20.9a.911.911 0 0 0-1.353-.8l-12.147 6.749z" transform="translate(-633.851 -407.363)"/>
                                    <path d="M4.5 0h6v9h-6A4.5 4.5 0 0 1 0 4.5 4.5 4.5 0 0 1 4.5 0z" transform="translate(0 6.864)"/>
                                    <path d="M690.553 431.412a4.5 4.5 0 0 0-3-4.238v8.477a4.5 4.5 0 0 0 3-4.239z" transform="translate(-660.553 -420.048)"/>
                                    <path d="M629.17 455.638v8.25a2.25 2.25 0 1 0 4.5 0v-8.25z" transform="translate(-623.17 -438.274)"/>
                                </g>
                            </svg>
                            <span className={'snb-text'}>공지사항</span>
                        </button>
                    </div>
                    <div className={'snb-item type-extend'}>
                        <button type={'button'} className={`snb-btn ${pathname.search('/usr/data') >= 0 ? 'type-on' : ''}`} onClick={() => handleNavItemClick(RoutePath.DataList)}>
                            <svg className={'snb-ico'} xmlns="http://www.w3.org/2000/svg" width="27.077" height="22.5">
                                <g>
                                    <path d="M621.489 589.085h-7.623a1.378 1.378 0 0 0-1.378 1.378v1.637h12z" transform="translate(-612.489 -589.085)"/>
                                    <path d="M0 0h22.481a4.6 4.6 0 0 1 4.6 4.6v8.885a4.6 4.6 0 0 1-4.6 4.6H4.6a4.6 4.6 0 0 1-4.6-4.6V0z" transform="translate(0 4.424)"/>
                                </g>
                            </svg>
                            <span className={'snb-text'}>자료실</span>
                        </button>
                    </div>
                    <div className={'snb-item type-extend'}>
                        <button type={'button'} className={`snb-btn ${pathname.search('/usr/qna') >= 0 ? 'type-on' : ''}`} onClick={() => handleNavItemClick(RoutePath.QnaList)}>
                            <svg className={'snb-ico'} xmlns="http://www.w3.org/2000/svg" width="27.076" height="24.008">
                                <g>
                                    <path d="M840.083 589.074h-23.77a1.653 1.653 0 0 0-1.653 1.653v14.7a1.653 1.653 0 0 0 1.653 1.653h13.347v4.93a1.072 1.072 0 0 0 1.83.758l5.666-5.688h2.928a1.653 1.653 0 0 0 1.653-1.653v-14.7a1.653 1.653 0 0 0-1.654-1.653zm-5.885 7.5a1.5 1.5 0 1 1-1.5 1.5 1.5 1.5 0 0 1 1.502-1.498zm-12.063 3.026a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5zm6.039 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.501 1.5z" transform="translate(-814.66 -589.074)"/>
                                </g>
                            </svg>
                            <span className={'snb-text'}>고객센터</span>
                        </button>
                    </div>
                </div>
                <div className={'snb-btn-box'}>
                    {
                        loggedIn ? (
                            <>
                                <button type={'button'} className={'btn type-small type-apply'} onClick={handleLogoutClick}>로그아웃</button>
                                <button type={'button'} className={'btn type-small type-cancel'} onClick={handleMemberInfoClick}>마이페이지</button>
                            </>
                        ) : (
                            <>
                                <button type={'button'} className={'btn type-small type-apply'} onClick={handleLoginClick}>로그인</button>
                                <button className={'btn type-small type-cancel'} onClick={handleJoinClick}>회원가입</button>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export { Nav }